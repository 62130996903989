import React, { useState, useEffect } from "react";
import { FetchService } from '../../service/FetchService';
import configData from "../../config.json";
import { Link } from "react-router-dom";
import $ from 'jquery';
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Skeleton } from "@mui/material";
import LoaderSkeleton from "../LoaderSkeleton/LoaderSkeleton";

function Logofolio() {
  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [dataf, setDataf] = useState(null);
  const [data1f, setData1f] = useState(null);
  const [data2f, setData2f] = useState(null);
  const [data3, setData3] = useState(null);
  const [loadMoreCount, setLoadMoreCount] = useState({
    data: 6,
    data1: 6,
    data2: 6,
    data3: 6,
    dataf: 6,
    data1f: 6,
    data2f: 6,
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    $('body').removeClass('overflow-hidden');
    $('.footer-info').removeClass('z-inc');
    var prevScrollPos = window.pageYOffset;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      // Scroll down 
      if (prevScrollPos < currentScrollPos) {
        $(".header").addClass("header-hidden").removeClass("header-visible");
        $("#tile-1").addClass("header-hidden-m").removeClass("header-visible-m");
        $('.footer-info').removeClass('z-inc');
      }
      // Scroll up 
      else {
        var scroll = $(window).scrollTop();
        if (scroll <= 100) {
          $(".header").removeClass("header-hidden").removeClass("header-visible");
          $("#tile-1").removeClass("header-hidden-m").removeClass("header-visible-m");
          $('.footer-info').removeClass('z-inc');
        } else {
          $(".header").removeClass("header-hidden").addClass("header-visible");
          $("#tile-1").removeClass("header-hidden-m").addClass("header-visible-m");
          $('.footer-info').removeClass('z-inc');
        }
      }
      prevScrollPos = currentScrollPos;
    };

    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    if (width <= 768) {

      $("main").click(function () {
        $('.overlay-123').removeClass('d-block');
      });

      $(window).scroll(function () {
        $(".footer-info").removeClass("z-inc");
        if ($(window).scrollTop() + $(window).height() > ($(document).height() - 280)) {
          //you are at bottom
          $(".footer-info").addClass("z-inc");
        }
      });

      $(".footer-info .animate-image span").click(function () {
        $('.footer-info').addClass('z-inc');
        $('#btn-back-to-top').addClass('d-none');
        $('.overlay-123').addClass('d-block');
        disableScroll();
      });


      $(".load-more-btn").click(function () {
        $('.more-logos').css({ 'max-height': '+=3000px' });
      });
      $(".load-more-btn1").click(function () {
        $('.more-logos1').css({ 'max-height': '+=3000px' });
      });
      $(".load-more-btn2").click(function () {
        $('.more-logos2').css({ 'max-height': '+=3000px' });
      });

    }

    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    if (width >= 768) {

      $(window).scroll(function () {
        $(".footer-info").removeClass("z-inc");
        if ($(window).scrollTop() + $(window).height() > ($(document).height() - 660)) {
          //you are at bottom
          $(".footer-info").addClass("z-inc");

        }
      });

      $(".animate-image").hover(
        function () {
          $(".footer-info").addClass("z-inc");
          $('#btn-back-to-top').addClass('d-none');
          //$('body').addClass('overflow-hidden');
          $('.overlay-123').addClass('d-block');
          disableScroll();
        },
        function () {
          $('#btn-back-to-top').removeClass('d-none');
          //$('body').removeClass('overflow-hidden');
          $('.overlay-123').removeClass('d-block');
          enableScroll();
        }
      );

      $(".load-more-btn").click(function () {
        $('.more-logos').css({ 'max-height': '+=1250px' });
        //        $('.more-logos').css({ 'transition': 'max-height 1s ease' });
        //        setTimeout(function () { $('.more-logos').css({ 'transition': '' });}, 1000);
      });
      $(".load-more-btn1").click(function () {
        $('.more-logos1').css({ 'max-height': '+=1250px' });
      });
      $(".load-more-btn2").click(function () {
        $('.more-logos2').css({ 'max-height': '+=1250px' });
      });


    }

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 200) {
        $(".header").removeClass("bg-none");
        $("#btn-back-to-top").addClass("d-block");
      } else {
        $(".header").addClass("bg-none");
        $("#btn-back-to-top").removeClass("d-block");
      }
    });




    $(".overlay-123").click(function () {
      $('.overlay-123').removeClass('d-block');
      setTimeout(function () { $('.footer-info').removeClass('z-inc'); }, 500);
      enableScroll();
    });



    $(".load-more-btn").click(function () {
      $('.more-logos').addClass('show');
      setTimeout(function () { $('.more-logos .logo-marks').addClass('test'); }, 200);
      //  $('.more-logos').css({ 'max-height' : '+=1000px' });
    });

    $(".load-more-btn1").click(function () {
      $('.more-logos1').addClass('show');
      setTimeout(function () { $('.more-logos1 .logo-marks').addClass('test'); }, 200);
      //  $('.more-logos').css({ 'max-height' : '+=1000px' });
    });

    $(".load-more-btn2").click(function () {
      $('.more-logos2').addClass('show');
      setTimeout(function () { $('.more-logos2 .logo-marks').addClass('test'); }, 200);
      //  $('.more-logos').css({ 'max-height' : '+=1000px' });
    });

    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    function preventDefault(e) {
      e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; }
      }));
    } catch (e) { }

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    // call this to Disable
    function disableScroll() {
      window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
      window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
      window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
      window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    }

    // call this to Enable
    function enableScroll() {
      window.removeEventListener('DOMMouseScroll', preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener('touchmove', preventDefault, wheelOpt);
      window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    }
  })
  {/*
  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchService = new FetchService();

        const config = {
          method: 'get',
          url: configData.SERVER_URL + 'logoflio/search?q=word_mark&fields=scope_category',
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y'
          }
        }


        const response = await fetchService.getService(config);
        console.log('Data fetched:', response);
        setData(response?.result || []);
        setDataf(response?.result || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  */}

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchService = new FetchService();

        const config = {
          method: 'get',
          url: configData.SERVER_URL + 'logoflio/search?q=word_mark&fields=scope_category',
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y'
          }
        }

        const response = await fetchService.getService(config);
        console.log('Data fetched:', response);
        setData(response?.result || []);
        setDataf(response?.result || []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after fetch
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData1 = async () => {
      try {
        const fetchService = new FetchService();
        const config = {
          method: 'get',
          url: configData.SERVER_URL + 'logoflio/search?q=symbol_type&fields=scope_category',
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y'
          }
        }
        const response = await fetchService.getService(config);
        console.log('Data1 fetched:', response); // Log fetched data
        setData1(response?.result || []);
        setData1f(response?.result || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData1();
  }, []);

  useEffect(() => {
    const fetchData2 = async () => {
      try {
        const fetchService = new FetchService();
        const config = {
          method: 'get',
          url: configData.SERVER_URL + 'logoflio/search?q=crest_form&fields=scope_category',
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y'
          }
        }
        const response = await fetchService.getService(config);
        console.log('Data2 fetched:', response); // Log fetched data
        setData2(response?.result || []);
        setData2f(response?.result || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData2();
  }, []);

  useEffect(() => {
    const fetchData3 = async () => {
      try {
        const fetchService = new FetchService();
        const config = {
          method: 'get',
          url: configData.SERVER_URL + 'logoflio/search?q=crest_form&fields=scope_category',
          headers: {
            'Content-Type': 'application/json',
            'X-Auth-Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y'
          }
        }
        const response = await fetchService.getService(config);
        console.log('Data2 fetched:', response); // Log fetched data
        setData3(response?.result || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData3();
  }, []);

  const handleLoadMore = (category) => {
    setLoadMoreCount(prevState => ({
      ...prevState,
      [category]: prevState[category] + 6
    }));
  };

  return (
    <div className="logomarks">
      <div className="header bg-none">
        <Navbar />
      </div>
      <main className="main-sec">
        <section className="contact-page about-page">
          <div className="container px-0">

            <div className="intro">
              <h4 className="fw-normal about-head f-m-0 mt-0 color-black mb-0 ">
                Form design embodies a project's vision, reflecting the brand's identity and elevating its integrity. Effective logos captivate and guide viewers to the right product with subtle impact. Our goal is to achieve this alignment, presenting our work as the "Face of the Brand" in this folio.
              </h4>
            </div>
            <div className="project-portfolio logomarks ph-60">
              {loading ? (<LoaderSkeleton page="Logofolio" />) : (
                <div className="row pt-md-6">
                  <section className="logo-heading"><h2>Word Mark</h2>
                    <div className="horizontal-line"></div>
                  </section>
                  {dataf && dataf.slice(0, loadMoreCount.dataf).map((item, index) => (
                    <div className="logo-marks mb-3 mt-2 col-md-4 col-sm-6 col-12 filter scope" key={index}>
                      <div className="card border-0 justify-content-center align-items-center">
                        <img src={configData.API_BASE_URL + '/' + item.logo} className="" alt="" />
                      </div>
                    </div>
                  ))}

                  <div className="more-logos column-gap-4 row-gap-0 d-md-flex flex-wrap">
                    {data && data.slice(6, loadMoreCount.data).map((item, index) => (

                      <div className="logo-marks mb-3 mt-2 col-md-4 col-sm-6 col-12 filter scope animBoxTest" key={index}>
                        <div className="card border-0 justify-content-center align-items-center">
                          <img src={configData.API_BASE_URL + '/' + item.logo} className="" alt="" />
                        </div>
                      </div>

                    ))}
                  </div>


                  {data && loadMoreCount.data < data.length && (
                    <div class="col-12 text-center">
                      <button onClick={() => handleLoadMore('data')} style={{ visibility: 'visible' }} class="load-more-btn bg-transparent border-0 text-decoration-underline color-gray text-uppercase">
                        Load More
                        <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.13868 0.612092L7.74417 0.612091L7.74417 11.6212L12.6753 6.69004L13.8221 7.83682L6.94142 14.7175L0.0607317 7.83682L1.20751 6.69004L6.13868 11.6212L6.13868 0.612092Z">
                          </path>
                        </svg>
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div className="row pt-md-5">
                <section className="logo-heading">
                  <h2>Symbol Type</h2>
                  <div className="horizontal-line"></div>
                </section>
                {data1f && data1f.slice(0, loadMoreCount.data1f).map((item, index) => (
                  <div className="logo-marks mb-3 mt-2 col-md-4 col-sm-6 col-12 filter scope" key={index}>
                    <div className="card border-0 justify-content-center align-items-center">
                      <img src={configData.API_BASE_URL + '/' + item.logo} className="" alt="" />
                    </div>
                  </div>
                ))}

                <div className="more-logos1 column-gap-4 row-gap-0 d-md-flex flex-wrap">
                  {data1 && data1.slice(6, loadMoreCount.data1).map((item, index) => (

                    <div className="logo-marks mb-3 mt-2 col-md-4 col-sm-6 col-12 filter scope" key={index}>
                      <div className="card border-0 justify-content-center align-items-center">
                        <img src={configData.API_BASE_URL + '/' + item.logo} className="" alt="" />
                      </div>
                    </div>

                  ))}
                </div>



                {data1 && loadMoreCount.data1 < data1.length && (
                  <div class="col-12 text-center">
                    <button onClick={() => handleLoadMore('data1')} style={{ visibility: 'visible' }} class="load-more-btn1 bg-transparent border-0 text-decoration-underline color-gray text-uppercase">
                      Load More
                      <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.13868 0.612092L7.74417 0.612091L7.74417 11.6212L12.6753 6.69004L13.8221 7.83682L6.94142 14.7175L0.0607317 7.83682L1.20751 6.69004L6.13868 11.6212L6.13868 0.612092Z">
                        </path>
                      </svg>
                    </button>
                  </div>
                )}
              </div>

              <div className="row pt-md-5">
                <section className="logo-heading">
                  <h2>Crest Form</h2>
                  <div className="horizontal-line"></div>
                </section>
                {data2f && data2f.slice(0, loadMoreCount.data2f).map((item, index) => (
                  <div className="logo-marks mb-3 mt-2 col-md-4 col-sm-6 col-12 filter scope" key={index}>
                    <div className="card border-0 justify-content-center align-items-center">
                      <img src={configData.API_BASE_URL + '/' + item.logo} className="" alt="" />
                    </div>
                  </div>
                ))}


                <div className="more-logos2 column-gap-4 row-gap-0 d-md-flex flex-wrap">
                  {data2 && data2.slice(6, loadMoreCount.data2).map((item, index) => (

                    <div className="logo-marks mb-3 mt-2 col-md-4 col-sm-6 col-12 filter scope" key={index}>
                      <div className="card border-0 justify-content-center align-items-center">
                        <img src={configData.API_BASE_URL + '/' + item.logo} className="" alt="" />
                      </div>
                    </div>

                  ))}
                </div>

                {data2 && loadMoreCount.data2 < data2.length && (
                  <div class="col-12 text-center">
                    <button onClick={() => handleLoadMore('data2')} style={{ visibility: 'visible' }} class="load-more-btn2 bg-transparent border-0 text-decoration-underline color-gray text-uppercase">
                      Load More
                      <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.13868 0.612092L7.74417 0.612091L7.74417 11.6212L12.6753 6.69004L13.8221 7.83682L6.94142 14.7175L0.0607317 7.83682L1.20751 6.69004L6.13868 11.6212L6.13868 0.612092Z">
                        </path>
                      </svg>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Logofolio;
