import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FetchService } from "../../service/FetchService";
import configData from "../../config.json";
import Navbar from "../Navbar";
import Footer from "../Footer";
import "./ProjectDetails.css";
import Tab from "./Tab"; // Import the Tab component
import $ from "jquery";
import LoaderSkeleton from "../LoaderSkeleton/LoaderSkeleton";



function Projects() {
  const scrollContainerRef = useRef(null);
  const [data, setData] = useState(null);
  const [dataf, setDataf] = useState(null);
  const [showProject, setshowProject] = useState(true);
  const [showScope, setshowScope] = useState(false);
  const [showIndustry, setshowIndustry] = useState(false);
  const [groupedByIndustry, setgroupedByIndustry] = useState([]);
  const [groupedByScope, setgroupedByScope] = useState([]);
  const [viewID, setViewID] = useState("");
  const [viewText, setViewText] = useState(true);
  const [transformed, setTransformed] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [hegightP, setHegightP] = useState("auto !important");
  const [hegightS, setHegightS] = useState("0px !important");
  const [hegightI, setHegightI] = useState("0px !important");
  const ref = useRef(null);
  const [viewCategories, SetViewCategories] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state
  const [pageNo, setPageNo] = useState(1)
  const [totalPages, setTotalPages] = useState()

  const [loadMoreCount, setLoadMoreCount] = useState({
    data: 45,
    dataf: 45,
  });
  


  useEffect(() => {
    $('body').removeClass('overflow-hidden');
    $('.footer-info').removeClass('z-inc');
    var prevScrollPos = window.pageYOffset;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      // Scroll down 
      if (prevScrollPos < currentScrollPos) {
        $(".header").addClass("header-hidden").removeClass("header-visible");
        $("#tile-1").addClass("header-hidden-m").removeClass("header-visible-m");
        $('.footer-info').removeClass('z-inc');
      }
      // Scroll up 
      else {
        var scroll = $(window).scrollTop();
        if (scroll <= 100) {
          $(".header").removeClass("header-hidden").removeClass("header-visible");
          $("#tile-1").removeClass("header-hidden-m").removeClass("header-visible-m");
          $('.footer-info').removeClass('z-inc');
        } else {
          $(".header").removeClass("header-hidden").addClass("header-visible");
          $("#tile-1").removeClass("header-hidden-m").addClass("header-visible-m");
          $('.footer-info').removeClass('z-inc');
        }
      }
      prevScrollPos = currentScrollPos;
    };

    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    if (width <= 768) {

      $("main").click(function () {
        $('.overlay-123').removeClass('d-block');
      });

      $(window).scroll(function () {
        $(".footer-info").removeClass("z-inc");
        if ($(window).scrollTop() + $(window).height() > ($(document).height() - 280)) {
          //you are at bottom
          $(".footer-info").addClass("z-inc");
        }
      });

      $(".footer-info .animate-image span").click(function () {
        $('.footer-info').addClass('z-inc');
        $('#btn-back-to-top').addClass('d-none');
        $('.overlay-123').addClass('d-block');
        disableScroll();
      });

    }

    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    if (width >= 768) {

      $(window).scroll(function () {
        $(".footer-info").removeClass("z-inc");
        if ($(window).scrollTop() + $(window).height() > ($(document).height() - 660)) {
          //you are at bottom
          $(".footer-info").addClass("z-inc");
          console.log(width);
        }
      });

      $(".animate-image").hover(
        function () {
          $(".footer-info").addClass("z-inc");
          $('#btn-back-to-top').addClass('d-none');
          //$('body').addClass('overflow-hidden');
          $('.overlay-123').addClass('d-block');
          disableScroll();
        },
        function () {
          $('#btn-back-to-top').removeClass('d-none');
          //$('body').removeClass('overflow-hidden');
          $('.overlay-123').removeClass('d-block');
          enableScroll();
        }
      );
    }


    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 200) {
        $(".header").removeClass("bg-none");
        $("#btn-back-to-top").addClass("d-block");
      } else {
        $(".header").addClass("bg-none");
        $("#btn-back-to-top").removeClass("d-block");
      }
    });

    $(".overlay-123").click(function () {
      $('.overlay-123').removeClass('d-block');
      setTimeout(function () { $('.footer-info').removeClass('z-inc'); }, 500);
      enableScroll();
    });

    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    function preventDefault(e) {
      e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; }
      }));
    } catch (e) { }

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    // call this to Disable
    function disableScroll() {
      window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
      window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
      window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
      window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    }

    // call this to Enable
    function enableScroll() {
      window.removeEventListener('DOMMouseScroll', preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener('touchmove', preventDefault, wheelOpt);
      window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    }
  })


  useEffect(() => {
    const fetchService = new FetchService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + `product/projectslist?items=2000`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y",
      },
    };
    fetchService.getService(config)
      // .then((data) => setData(data["result"]));
      .then((data) => {
        console.log(data, "===========>>>>>>>===Data check api=======")
        setData(data["result"]);
        setDataf(data["result"])
        setTotalPages(data['pagination']?.pages)
      });
  }, []);


  const handlePageChange = async ()=> {
    setPageNo(pageNo + 1)
    const fetchService = new FetchService();
    const config = {
      method: "get",
      url: configData.SERVER_URL + `product/projectslist?items=45&page=${pageNo + 1}`,
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token":
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y",
      },
    };
    fetchService.getService(config)
      // .then((data) => setData(data["result"]));
      .then((dataa) => {
        console.log(data, "===========>>>>>>>===Data check api=======")
        setData([...data, ...dataa["result"]]);
        setTotalPages(dataa['pagination']?.pages)
      });
  }

  console.log('dtaaaaa', data)


  // useEffect(() => {
  //   // Function to check localStorage value and call another function
  //   const checkLocalStorage = () => {
  //     const activeTab = localStorage.getItem("active");
  //     if (activeTab !== null) {
  //       // Call your function here if localStorage value is not null
  //       showCategory(activeTab);
  //     } else {
  //       localStorage.setItem("active", "projects");
  //     }
  //   };

  //   // Call the function on component mount and on popstate (back/forward navigation)
  //   checkLocalStorage();
  //   window.addEventListener("popstate", checkLocalStorage);

  //   // Cleanup the event listener when component unmounts
  //   return () => {
  //     window.removeEventListener("popstate", checkLocalStorage);
  //   };
  // }, []);

  // useEffect(() => {
  //   const groupedByIndustryData =
  //     data &&
  //     data.reduce((acc, product) => {
  //       const industry = product.industry_category.split(",");
  //       industry.forEach((category) => {
  //         const trimmedCategory = category.trim();
  //         if (!acc[trimmedCategory]) {
  //           acc[trimmedCategory] = [];
  //         }

  //         acc[trimmedCategory].push(product);
  //       });
  //       const keys = Object.keys(acc).sort();

  //       const sortedAcc = {};
  //       keys.forEach((key) => {
  //         sortedAcc[key] = acc[key];
  //       });
  //       //console.log(sortedAcc);
  //       return sortedAcc;
  //     }, {});

  //   setgroupedByIndustry(groupedByIndustryData);

  //   const groupedByScopeData =
  //     data &&
  //     data.reduce((acc, product) => {
  //       const industry = product.scope_category.split(",");
  //       industry.forEach((category) => {
  //         const trimmedCategory = category.trim();
  //         if (!acc[trimmedCategory]) {
  //           acc[trimmedCategory] = [];
  //         }

  //         acc[trimmedCategory].push(product);
  //       });

  //       const keys = Object.keys(acc).sort();

  //       const sortedAcc = {};
  //       keys.forEach((key) => {
  //         sortedAcc[key] = acc[key];
  //       });

  //       return sortedAcc;
  //     }, {});
  //   setgroupedByScope(groupedByScopeData);
  // }, [data]);


  useEffect(() => {
    if (data) {
      setLoading(true); // Set loading state to true when data fetching starts
      const groupedByIndustryData =
        data &&
        data.reduce((acc, product) => {
          const industry = product.industry_category.split(",");
          industry.forEach((category) => {
            const trimmedCategory = category.trim();
            if (!acc[trimmedCategory]) {
              acc[trimmedCategory] = [];
            }

            acc[trimmedCategory].push(product);
          });

          const keys = Object.keys(acc).sort();

          const sortedAcc = {};
          keys.forEach((key) => {
            sortedAcc[key] = acc[key];
          });
          return sortedAcc;
        }, {});

      setgroupedByIndustry(groupedByIndustryData);


      const groupedByScopeData =
        data &&
        data.reduce((acc, product) => {
          const industry = product.scope_category.split(",");
          industry.forEach((category) => {
            const trimmedCategory = category.trim();
            if (!acc[trimmedCategory]) {
              acc[trimmedCategory] = [];
            }

            acc[trimmedCategory].push(product);
          });

          const keys = Object.keys(acc).sort();

          const sortedAcc = {};
          keys.forEach((key) => {
            sortedAcc[key] = acc[key];
          });

          return sortedAcc;
        }, {});
      setgroupedByScope(groupedByScopeData);
      setLoading(false); // Set loading state to false when data fetching is complete
    }
  }, [data]);


  useEffect(() => {
    if (hegightP) {
      setHegightP("auto !important");
      setHegightS("0px !important");
      setHegightI("0px !important");
    }
    if (hegightS) {
      setHegightP("0px !important");
      setHegightS("auto !important");
      setHegightI("0px !important");
    }
  }, [hegightP, hegightS, hegightI]);

  const showCategory = (category) => {
    if (category == "projects") {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Smooth scrolling behavior
      });
      // Delay execution of the next line of code by 500 milliseconds (adjust as needed)
      setTimeout(() => {
        localStorage.setItem("active", "projects");
        setshowProject(true);
        setshowScope(false);
        setshowIndustry(false);
        setTransformed(true);
        setHegightP("auto");
      }, 0);

      requestAnimationFrame(() => {
        scrollContainerRef.current.scrollLeft = 0;
      });

    } else if (category == "scope") {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Smooth scrolling behavior
      });
      setTimeout(() => {
        localStorage.setItem("active", "scope");

        setshowProject(false);
        setshowScope(true);
        setshowIndustry(false);
        setTransformed(true);
        setHegightS("auto");
      }, 0);

      requestAnimationFrame(() => {
        scrollContainerRef.current.scrollLeft = 50;
      });

    } else if (category == "industry") {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Smooth scrolling behavior
      });
      setTimeout(() => {
        localStorage.setItem("active", "industry");
        setshowProject(false);
        setshowScope(false);
        setshowIndustry(true);
        setHegightI("auto");
      }, 0);

      requestAnimationFrame(() => {
        scrollContainerRef.current.scrollLeft = 150;
      });

    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Optional: Smooth scrolling behavior
      });
      setTimeout(() => {
        localStorage.setItem("active", "projects");
        setshowProject(true);
        setshowScope(false);
        setshowIndustry(false);
        setHegightP("0px");
      }, 0);

      requestAnimationFrame(() => {
        scrollContainerRef.current.scrollLeft = 0;
      });

    }
  };

  const viewAll = (category) => {
    setViewID(category);
    setViewText(!viewText);
    // this code for show less and all
    if (viewCategories.includes(category)) {
      SetViewCategories(viewCategories.filter((item) => item !== category));
    } else {
      SetViewCategories([...viewCategories, category])
    }
    //end  // this code for show less and all
    // window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
  };

  const showAllCategory = (item) => {
    const category = item.industry_category + "," + item.scope_category;
    let splitArray = category.split(",");

    // Add spaces after each comma
    let formattedString = splitArray.join(", ");

    // Replace the last comma with "and" and remove the space after it
    formattedString = formattedString.replace(/,([^,]*)$/, " and$1");
    return formattedString;
  };

  const showAllScopes = (item) => {
    const category = item.scope_category;
    let splitArray = category.split(",");

    // Add spaces after each comma
    let formattedString = splitArray.join(", ");

    // Replace the last comma with "and" and remove the space after it
    formattedString = formattedString.replace(/,([^,]*)$/, " and$1");
    return formattedString;
  };

  const showAllIndustry = (item) => {
    const category = item.industry_category;
    let splitArray = category.split(",");

    // Add spaces after each comma
    let formattedString = splitArray.join(", ");

    // Replace the last comma with "and" and remove the space after it
    formattedString = formattedString.replace(/,([^,]*)$/, " and$1");
    return formattedString;
  };

  const [activetab, setActiveTab] = useState('projects');
  // const activetab = localStorage.getItem("active");
  if (activetab !== null) {
    //showCategory(activetab);
  }

  // Chunk the data into sets of three
  const chunkedData = (arr, size) => {
    return Array.from({ length: Math.ceil(arr.length / size) }, (v, index) =>
      arr.slice(index * size, index * size + size)
    );
  };

  // Function to render a single item div
  const renderDataItem = (item) => (
    <div className="gallery_product mb-3 col-md-4 col-sm-6 col-12 filter industry">
      <Link
        to={{
          pathname: `/project/${item.productName.toLowerCase().replace(/[^a-z0-9-]/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '')}`,
          state: { data: item._id },
        }}
      >
        <div className="card border-0 ">
        {item.new_tag ?  (<span className="new-tag">New</span>) : (<span></span>)}
          <div className="img-box">
            {/* <img
              src={configData.API_BASE_URL + "/" + item.images}
              className="img-fluid"
              alt=""
            /> */}
                  {isVideo(item.images) ? (
                      <video loop autoPlay muted  className="img-fluid">
                        <source src={configData.API_BASE_URL + "/" + item.images} type={`video/${item.images.split('.').pop()}`} />
                      </video>
                    ) : (
                      <img src={configData.API_BASE_URL + "/" + item.images} alt="Media content"  className="img-fluid" />
                    )}
          </div>
          <div className="card-body pe-0 ps-0 text-start">
            <h3 className="mb-0 color-black fs-22">{item.productName}</h3>
            <p className="fs-6 lh-scope mt-1 ibm-sans fw-normal mb-0 color-black">
              {
                activetab == "projects" || activetab == null
                  ? showAllScopes(item)
                  : activetab == "scope" || showScope
                    ? showAllScopes(item)
                    : activetab == "industry" || showIndustry
                      ? showAllScopes(item)
                      : showAllScopes(item)
              }
            </p>
          </div>
        </div>
      </Link>
    </div>
  );

  // Render sets of three pairs
  const renderDataPairs = (projectData) => {
    const chunkedDataArray = chunkedData(projectData, 3);

    return chunkedDataArray.map((chunk, index) => (
      <div key={"scroll" + index} className="row pr-mob-32">
        {console.log('chunkkkkkk', chunk)}
        {chunk.map((item) => renderDataItem(item))}
      </div>
    ));
  };

  const isVideo = (url) => {
    const videoExtensions = ['mp4', 'webm', 'ogg', 'avi', 'mov', 'wmv', 'flv', 'mkv'];
    const extension = url.split('.').pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  const handleLoadMore = (category) => {
    setLoadMoreCount(prevState => ({
      ...prevState,
      [category]: prevState[category] + 45
    }));
  };


  $(".load-more-btn").click(function () {
    console.log('loadMoreCount?.data', loadMoreCount?.data)
    if(loadMoreCount?.data == 45) {
      $('.newMoreProjects1').addClass('show');
      setTimeout(function () { $('.newMoreProjects1 .gallery_product').addClass('test'); }, 200);
      $('.newMoreProjects1').css({ 'max-height' : '+=13000px' });
    } else {
      $('.newMoreProjects2').addClass('show');
      setTimeout(function () { $('.newMoreProjects2 .gallery_product').addClass('test'); }, 200);
      $('.newMoreProjects2').css({ 'max-height' : '+=13000px' });
    }
  });

  return (
    <>
      <div className="project-page-1">
        <div className="header bg-none">
          <Navbar />
          <div
            className="filter-tab text-md-end text-start position-relative"
            id="tile-1"
          >
            <ul
              className="sticky-nav nav nav-tabs justify-content-md-end justify-content-start border-0 page-tabs"
              id="myTab"
              role="tablist"
              ref={scrollContainerRef}
            >
              <li
                className="nav-item list-group-item"
                role="presentation"
                id="tab-1"
              >
                <button
                  className="nav-link active bg-transparent border-0 px-3  text-start text-uppercase"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                  onClick={() => {showCategory("projects") 
                    setActiveTab('projects')}}
                >
                  All{" "}
                  <span>
                    Projects
                    <div
                      //className="divider-1 trans-00"
                      id="divider-all"
                      //style={{ transform: "translateX(0%)" }}
                      className={`divider-1 ${activetab == "projects" || activetab == null
                        ? "transformed"
                        : activetab == "scope" || showScope
                          ? "myTabActivetransform"
                          : activetab == "industry" || showIndustry
                            ? "myTabActivetransform"
                            : "notransformed"
                        }`}
                    />
                  </span>
                </button>
              </li>
              <li
                className="nav-item list-group-item"
                role="presentation"
                id="tab-2"
              >
                <button
                  className="nav-link bg-transparent border-0 px-0 text-start text-uppercase"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="false"
                  onClick={() => {showCategory("scope")
                    setActiveTab('scope')
                  }}
                >
                  Sort by{" "}
                  <span>
                    Scope
                    <div
                      // className="divider-1 trans-00"
                      id="divider-scope"
                      // style={{ transform: "translateX(-100%)" }}
                      className={`divider-1 ${activetab == "scope" || showScope
                        ? "transformed"
                        : activetab == "industry" || showIndustry
                          ? "myTabActivetransform"
                          : "notransformed"
                        }`}
                    />
                  </span>
                </button>
              </li>
              <li
                className="nav-item list-group-item"
                role="presentation"
                id="tab-3"
                type="button"
              >
                <button
                  className="nav-link bg-transparent border-0 text-start text-uppercase"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="contact-tab-pane"
                  aria-selected="false"
                  onClick={() => {showCategory("industry")
                    setActiveTab('industry')
                  }}
                >
                  Sort by{" "}
                  <span>
                    industry
                    <div
                      //className="divider-1 trans-00"
                      id="divider-industry"
                      //style={{ transform: "translateX(-200%)" }}
                      className={`divider-1 ${activetab == "industry" || showIndustry
                        ? "transformed"
                        : "notransformed"
                        }`}
                    />
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </div>
        {/* Project Gallery Start*/}
        <main className="main-sec">
          <section className="project-portfolio pv-60">
            <div className="container px-0">
              <div className="overflow-hidden">
                <div
                  className={`tab-content  ${activetab == "projects" || showProject
                    ? "myTabContentProject"
                    : ""
                    } ${activetab == "scope" || showScope ? "myTabContentScope" : ""
                    } ${activetab == "industry" || showIndustry
                      ? "myTabContentIndustry"
                      : ""
                    }`}
                  id="myTabContent"
                >
                  <div className="tab-pane all_tab active" id="all_projects">
                    <div
                      id="all_tab"
                      // className="box pt-4 mt-1"
                      className={`${(activetab == "projects") ? "box pt-4 mt-1 autoHeight" : "box pt-4 mt-1 autoHeightPx"
                        }`}
                    // style={{ height: activetab == 'projects' ? 'auto !important': '0px !important' }}
                    >
                      {loading ? (<LoaderSkeleton page="projects" />) : (
                        <>
                          <div className="row">
                            {dataf &&
                              dataf.slice(0, loadMoreCount.dataf).map((item, index) => (
                                <div
                                  key={"tab" + index}
                                  className="gallery_product mb-3 col-md-4 col-sm-6 col-12 filter scope"
                                >
                                  <Link
                                    to={{
                                      pathname: `/project/${item.productName.toLowerCase().replace(/[^a-z0-9-]/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '')}`,
                                      state: { data: item._id },
                                    }}
                                  >
                                    <div className="card border-0 ">
                                    {item.new_tag ?  (<span className="new-tag">New</span>) : (<span></span>)}
                                      <div className="img-box">
                                        {/* <img
                                          src={
                                            configData.API_BASE_URL +
                                            "/" +
                                            item.images
                                          }
                                          className="img-fluid"
                                          alt=""
                                        /> */}
                                          {isVideo(item.images) ? (
                                              <video loop autoPlay muted  className="img-fluid">
                                                <source src={configData.API_BASE_URL + "/" + item.images} type={`video/${item.images.split('.').pop()}`} />
                                              </video>
                                            ) : (
                                              <img src={configData.API_BASE_URL + "/" + item.images} alt="Media content"  className="img-fluid" />
                                            )}
                                      </div>
                                      <div className="card-body pe-0 ps-0 text-start">
                                        <h3 className="mb-0 color-black fs-22">
                                          {item.productName}
                                        </h3>
                                        <p className="fs-6 lh-scope mt-1 ibm-sans fw-normal mb-0 color-black">
                                          {showAllScopes(item)}
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              ))}
                          </div>
                          <div className="row newMoreProjects newMoreProjects1">
                            {data &&
                              data.slice(45, 90).map((item, index) => (
                                <div
                                  key={"tab" + index}
                                  className="gallery_product mb-3 col-md-4 col-sm-6 col-12 filter scope"
                                >
                                  <Link
                                    to={{
                                      pathname: `/project/${item.productName.toLowerCase().replace(/[^a-z0-9-]/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '')}`,
                                      state: { data: item._id },
                                    }}
                                  >
                                    <div className="card border-0 ">
                                    {item.new_tag ?  (<span className="new-tag">New</span>) : (<span></span>)}
                                      <div className="img-box">
                                        {/* <img
                                          src={
                                            configData.API_BASE_URL +
                                            "/" +
                                            item.images
                                          }
                                          className="img-fluid"
                                          alt=""
                                        /> */}
                                          {isVideo(item.images) ? (
                                              <video loop autoPlay muted  className="img-fluid">
                                                <source src={configData.API_BASE_URL + "/" + item.images} type={`video/${item.images.split('.').pop()}`} />
                                              </video>
                                            ) : (
                                              <img src={configData.API_BASE_URL + "/" + item.images} alt="Media content"  className="img-fluid" />
                                            )}
                                      </div>
                                      <div className="card-body pe-0 ps-0 text-start">
                                        <h3 className="mb-0 color-black fs-22">
                                          {item.productName}
                                        </h3>
                                        <p className="fs-6 lh-scope mt-1 ibm-sans fw-normal mb-0 color-black">
                                          {showAllScopes(item)}
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              ))}
                          </div>
                          <div className="row newMoreProjects newMoreProjects2">
                            {data &&
                              data.slice(90, 135).map((item, index) => (
                                <div
                                  key={"tab" + index}
                                  className="gallery_product mb-3 col-md-4 col-sm-6 col-12 filter scope"
                                >
                                  <Link
                                    to={{
                                      pathname: `/project/${item.productName.toLowerCase().replace(/[^a-z0-9-]/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '')}`,
                                      state: { data: item._id },
                                    }}
                                  >
                                    <div className="card border-0 ">
                                    {item.new_tag ?  (<span className="new-tag">New</span>) : (<span></span>)}
                                      <div className="img-box">
                                        {/* <img
                                          src={
                                            configData.API_BASE_URL +
                                            "/" +
                                            item.images
                                          }
                                          className="img-fluid"
                                          alt=""
                                        /> */}
                                          {isVideo(item.images) ? (
                                              <video loop autoPlay muted  className="img-fluid">
                                                <source src={configData.API_BASE_URL + "/" + item.images} type={`video/${item.images.split('.').pop()}`} />
                                              </video>
                                            ) : (
                                              <img src={configData.API_BASE_URL + "/" + item.images} alt="Media content"  className="img-fluid" />
                                            )}
                                      </div>
                                      <div className="card-body pe-0 ps-0 text-start">
                                        <h3 className="mb-0 color-black fs-22">
                                          {item.productName}
                                        </h3>
                                        <p className="fs-6 lh-scope mt-1 ibm-sans fw-normal mb-0 color-black">
                                          {showAllScopes(item)}
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                      {/* {
                        pageNo < totalPages && 
                        <p class="mb-0 text-center text-uppercase pt-3">
                          <Link onClick={handlePageChange} class="fs-5 color-gray hov-color-black" href="javascript:;">
                            View all Projects
                          </Link>
                        </p>
                      } */}

                      {data && loadMoreCount.data < data.length && (
                        <div class="col-12 text-center">
                          <button onClick={() => handleLoadMore('data')} style={{ visibility: 'visible' }} class="load-more-btn bg-transparent border-0 text-decoration-underline color-gray text-uppercase">
                            Load More
                            <svg width="14" height="15" viewBox="0 0 14 15" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M6.13868 0.612092L7.74417 0.612091L7.74417 11.6212L12.6753 6.69004L13.8221 7.83682L6.94142 14.7175L0.0607317 7.83682L1.20751 6.69004L6.13868 11.6212L6.13868 0.612092Z">
                              </path>
                            </svg>
                          </button>
                        </div>
                      )}

                    </div>
                  </div>

                  {/* <div className="tab-pane about-tab" id="scope">
                    <div id="about_tab" 
                    // style={{ 
                    //   height: activetab == 'scope' ? 'auto': '0px' 
                    // }}
                    className={`${(activetab == 'scope' || showScope) ? 'autoHeight' : 'autoHeightPx'} `}
                    >
                      {groupedByScope && Object.entries(groupedByScope).map(([category, projectData]) => (
                        <div key={"about_tab"+category}>
                          <div className="d-flex tab-head justify-content-between align-items-end pb-md-3 pb-3 mt-md-3 mt-4 pr-32">
                            <h3 className="display-5 color-black mb-0 fw-bold">
                              {category}
                            </h3>
                            {
                              projectData.length > 3 ?
                                <Link
                                  to={`/project`}
                                  onClick={(event) => viewAll(category)}
                                  className={viewText === true ? "color-black text-decoration-none text-uppercase lh-sm d-flex align-items-center text-nowrap" : "color-black text-decoration-none text-uppercase lh-sm d-flex align-items-center text-nowrap icon-rotate-180"}
                                >
                                  <span className="">
                                    {category == viewID ? viewText === true ? "View all" : "View less" : "View all"} <font className="desktop-vis">projects</font>
                                  </span>
                                  <i className="far fa-angle-down fs-5 ms-md-2 ms-1" />
                                </Link>
                                : null
                            }
                          </div>
                          <div className="row scroll">
                            {projectData && projectData.slice(0, 3).map((item, index) => (
                              <div key={"scrol"+index} className="gallery_product mb-3 col-md-4 col-sm-6 col-12 filter scope">
                                <Link to={{ pathname: item.productName.replace(/\s+/g, '-').toLowerCase(), state: { data: item._id }}}>
                                  <div className="card border-0 ">
                                    <div className="img-box">
                                      <img
                                        src={configData.API_BASE_URL + '/' + item.images}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-body pe-0 ps-0 text-start">
                                      <h3 className="mb-0 color-black fs-22">{item.productName}</h3>
                                      <p className="fs-6 lh-scope mt-1 ibm-sans fw-normal mb-0 color-black">
                                        {showAllCategory(item)}
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            ))}
                          </div>
                          <div id={category}
                            style={{ display: viewText == false && viewID === category ? "block" : "none" }}
                            className={viewText == false && viewID === category ? "hidden dis-block" : "hidden dis-none"}>
                            {renderDataPairs(projectData && projectData.slice(3, projectData.length))}
                           </div>
                        </div>
                      ))}



                    </div>
                  </div> */}


                  <div className="tab-pane about-tab" id="scope">
                    {loading ? (<LoaderSkeleton />) : (
                      <div id="about_tab" className={`${(activetab == 'scope' || showScope) ? 'autoHeight' : 'autoHeightPx'}`}>

                        {groupedByScope &&
                          Object.entries(groupedByScope).map(
                            ([category, projectData]) => (
                              <div key={category}>
                                <div className="d-flex tab-head justify-content-between align-items-end pb-md-3 pb-3 mt-md-3 mt-4 pr-32">
                                  <h3 className="display-5 color-black mb-0 fw-bold">
                                    {category}
                                  </h3>
                                  {projectData.length > 3 && (
                                    <Link
                                      to={`/project`}
                                      onClick={() => viewAll(category)}
                                      className="view-all-link"
                                      // data-bs-toggle="collapse" data-bs-target={`#additional-${category.replace(/\s+/g, '')}`} type="button" aria-expanded="false" aria-controls={`additional-${category.replace(/\s+/g, '')}`}
                                    >
                                      <span>
                                        {viewCategories.includes(category)
                                          ? "VIEW LESS"
                                          : "VIEW ALL"}{" "}
                                        <font className="desktop-vis">
                                          PROJECTS
                                        </font>
                                      </span>
                                      <i
                                        className={`far fs-5 ms-md-2 ms-1 ${viewCategories.includes(category)
                                          ? "fa-angle-up"
                                          : "fa-angle-down"
                                          }`}
                                      />
                                    </Link>
                                  )}
                                </div>
                                {/* Display the first 3 projects .sort((a, b) => a.productName > b.productName ? 1 : -1) */}
                                <div className={`row ${viewCategories.includes(category) ? "pr-mob-32" : "scroll"
                                  }`}>
                                  {projectData.slice(0, 3).map((item, index) => (
                                    <div
                                      key={item._id}
                                      className="gallery_product mb-3 col-md-4 col-sm-6 col-12 filter scope"
                                    >
                                      <Link to={{
                                        pathname: `/project/${item.productName.replace(/\s+/g, "-").toLowerCase()}`,
                                        state: { data: item._id },
                                      }}>
                                        <div className="card border-0">
                                        {item.new_tag ?  (<span className="new-tag">New</span>) : (<span></span>)}
                                          <div className="img-box">
                                            {/* <img
                                              src={`${configData.API_BASE_URL}/${item.images}`}
                                              className="img-fluid"
                                              alt=""
                                            /> */}
                                              {isVideo(item.images) ? (
                                                <video loop autoPlay muted  className="img-fluid">
                                                  <source src={configData.API_BASE_URL + "/" + item.images} type={`video/${item.images.split('.').pop()}`} />
                                                </video>
                                              ) : (
                                                <img src={configData.API_BASE_URL + "/" + item.images} alt="Media content"  className="img-fluid" />
                                              )}
                                          </div>
                                          <div className="card-body pe-0 ps-0 text-start">
                                            <h3 className="mb-0 color-black fs-22">{`${item.productName}`}</h3>
                                            <p className="fs-6 lh-scope mt-1 ibm-sans fw-normal mb-0 color-black">
                                              {showAllScopes(item)}
                                            </p>
                                            {/* Optionally display more details */}
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                                {/* Additional projects */}
                                <div
                                  id={`additional-${category}`}
                                  className={`additional-projects ${viewCategories.includes(category) ? "show" : ""
                                    }`}
                                >
                                  {renderDataPairs(projectData && projectData.slice(3, projectData.length))}
                                </div>
                              </div>
                            )
                          )}

                      </div>)}
                  </div>





                  <div className="tab-pane contact-tab" id="industry">
                    {loading ? (<LoaderSkeleton />) : (
                      <div id="contact_tab" className={`${(activetab == 'industry' || showIndustry) ? 'autoHeight' : 'autoHeightPx'} `}>
                        {groupedByIndustry &&

                          Object.entries(groupedByIndustry).map(
                            ([category, projectData]) => (
                              <div key={category}>
                                <div className="d-flex tab-head justify-content-between align-items-end pb-md-3 pb-3 mt-md-3 mt-4 pr-32">
                                  <h3 className="display-5 color-black mb-0 fw-bold">
                                    {category}
                                  </h3>
                                  {projectData.length > 3 && (
                                    <Link
                                      to={`/project`}
                                      onClick={() => viewAll(category)}
                                      className="view-all-link"
                                    >
                                      <span>
                                        {viewCategories.includes(category)
                                          ? "VIEW LESS"
                                          : "VIEW ALL"}{" "}
                                        <font className="desktop-vis">
                                          PROJECTS
                                        </font>
                                      </span>
                                      <i
                                        className={`far  fs-5 ms-md-2 ms-1 ${viewCategories.includes(category)
                                          ? "fa-angle-up"
                                          : "fa-angle-down"
                                          }`}
                                      />
                                    </Link>
                                  )}
                                </div>
                                {/* Display the first 3 projects .sort((a, b) => a.productName > b.productName ? 1 : -1) */}
                                <div className={`row ${viewCategories.includes(category) ? "pr-mob-32" : "scroll"
                                  }`}>
                                  {projectData.slice(0, 3).map((item, index) => (
                                    <div
                                      key={item._id}
                                      className="gallery_product mb-3 col-md-4 col-sm-6 col-12 filter scope"
                                    >
                                      <Link to={{
                                        pathname: `/project/${item.productName.toLowerCase().replace(/[^a-z0-9-]/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '')}`,
                                        state: { data: item._id },
                                      }}>
                                        <div className="card border-0">
                                          <div className="img-box">
                                          {item.new_tag ?  (<span className="new-tag">New</span>) : (<span></span>)}
                                            {/* <img
                                              src={`${configData.API_BASE_URL}/${item.images}`}
                                              className="img-fluid"
                                              alt=""
                                            /> */}
                                              {isVideo(item.images) ? (
                                                    <video loop autoPlay muted  className="img-fluid">
                                                      <source src={configData.API_BASE_URL + "/" + item.images} type={`video/${item.images.split('.').pop()}`} />
                                                    </video>
                                                  ) : (
                                                    <img src={configData.API_BASE_URL + "/" + item.images} alt="Media content"  className="img-fluid" />
                                                  )}
                                          </div>
                                          <div className="card-body pe-0 ps-0 text-start">
                                            <h3 className="mb-0 color-black fs-22">{`${item.productName}`}</h3>
                                            <p className="fs-6 lh-scope mt-1 ibm-sans fw-normal mb-0 color-black">
                                              {showAllScopes(item)}
                                            </p>
                                            {/* Optionally display more details */}
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                                {/* Additional projects */}
                                <div
                                  id={`additional-${category}`}
                                  className={`additional-projects ${viewCategories.includes(category) ? "show" : ""
                                    }`}
                                >
                                  {renderDataPairs(projectData && projectData.slice(3, projectData.length))}

                                </div>
                              </div>
                            )
                          )}
                      </div>
                    )}
                  </div>

                </div>
              </div>
            </div>
          </section>
        </main>
        {/* Project Gallery End */}
        <Footer />
        {/* <Helmet>
        <script src="./resource.js" type="text/jsx" />
      </Helmet> */}
      </div>
    </>
  );
}

export default Projects;
