import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import { FetchService } from '../../service/FetchService';
import configData from "../../config.json";
import Navbar_1 from "../Navbar_1";
import Footer_1 from "../Footer_1";

function LogofolioDetails() {
  const params = useParams();
  const id = params.id;
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchService = new FetchService();
    const config = {
      method: 'get',
      url: configData.SERVER_URL + 'logoflio/read/' + id,
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y'
      }
    }
    fetchService.getService(config).then(data => setData(data["result"]));
  }, []);

  return (
    <div className="logomarks">
      <Navbar_1 />
      <main className="main-sec">
        <section className="project-page">
        <div className="container px-0">
              <div className="project-intro pb-mob-0 pt-40">
                <div className="row">
                  <div className="col-md-6 col-12 order-md-0 order-2">
                    <h2 className="ibm-serif color-black mb-md-4 mb-2 mt-0 fw-bold">
                      {data && data.title}
                    </h2>
                    <h5 className="project-breadchrumb ibm-sans fw-normal mb-md-5 mb-40 underline-off-2">
                      {data && data.scope_category && data.scope_category.split(',').map((item, index) => (
                        <span className="navbar-brand">{item}   </span>
                      ))}
                      {data && data.industry_category && data.industry_category.split(',').map((item, index) => (
                        <span className="navbar-brand">{item}   </span>
                      ))}
                    </h5>
                  </div>
                  <div className="col-md-6 col-12 rel-tab">
                    <Link className="back-to-project" to="/logofolio">
                      <p className="ibm-sans mb-md-2 mb-0 mt-n50 text-md-end text-start fs-6 pt-md-3 pt-sm-3 underline-off-2">
                        <svg
                          width={16}
                          height={15}
                          viewBox="0 0 16 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-2"
                        >
                          <path d="M3 15V13H10.1C11.15 13 12.0625 12.6667 12.8375 12C13.6125 11.3333 14 10.5 14 9.5C14 8.5 13.6125 7.66667 12.8375 7C12.0625 6.33333 11.15 6 10.1 6H3.8L6.4 8.6L5 10L0 5L5 0L6.4 1.4L3.8 4H10.1C11.7167 4 13.1042 4.525 14.2625 5.575C15.4208 6.625 16 7.93333 16 9.5C16 11.0667 15.4208 12.375 14.2625 13.425C13.1042 14.475 11.7167 15 10.1 15H3Z" />
                        </svg>
                         BACK TO LOGOFLIO
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="project-intro pt-16">
                  {data && ReactHtmlParser(data && data.content)}
                </div>
              </div>
            </div>
          
          
          {/* <div className="container px-0">
            <div className="project-intro pb-mob-0">
              <div className="row">
                <div className="col-md-6 col-12 order-md-0 order-2">
                  <h1 className="display-5 ibm-serif color-black mb-2 mt-0 fw-bold">
                    {data && data.title}
                  </h1>
                  <h5 className="project-breadchrumb ibm-sans fw-normal mb-md-5 mb-40 underline-off-2">
                    {data && data.scope_category && data.scope_category.split(',').map((item, index) => (
                      <Link className="navbar-brand" to="#">{item}   </Link>
                    ))}
                  </h5>
                </div>
                <div className="col-md-6 col-12 rel-tab">
                  <Link className="back-to-project" to="/logomarks">
                    <p className="ibm-sans mb-md-2 mb-0 mt-n50 text-md-end text-start fs-6 pt-md-3 pt-sm-3 underline-off-2">
                      <svg
                        width={16}
                        height={15}
                        viewBox="0 0 16 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="me-2"
                      >
                        <path
                          d="M3 15V13H10.1C11.15 13 12.0625 12.6667 12.8375 12C13.6125 11.3333 14 10.5 14 9.5C14 8.5 13.6125 7.66667 12.8375 7C12.0625 6.33333 11.15 6 10.1 6H3.8L6.4 8.6L5 10L0 5L5 0L6.4 1.4L3.8 4H10.1C11.7167 4 13.1042 4.525 14.2625 5.575C15.4208 6.625 16 7.93333 16 9.5C16 11.0667 15.4208 12.375 14.2625 13.425C13.1042 14.475 11.7167 15 10.1 15H3Z"
                          fill="#999999"
                        />
                      </svg>
                  BACK TO LOGOFLIO
                </p>
                  </Link>
                </div>
              </div>

              <div className="project-intro pt-16">
                {data && ReactHtmlParser(data && data.content)}
              </div>
            </div>
          </div>
          */}
        </section> 
      </main>
      <Footer_1 />
    </div>
  );
}

export default LogofolioDetails;
