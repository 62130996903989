import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import './LoaderSkeleton.css';

const LoaderSkeleton = ({ page }) => {
  let arr = [1, 2, 3];
  return (
    <>
      <div className="loader-container2">
        {page === 'Logofolio' && (
          <>
            <div className="header-text">Header Text</div>
            <div className="horizontal-line"></div>
          </>
        )}
      </div>
      <div className="loader-container">
        {
          arr.map((data, index) => {
            return (
              <Stack spacing={1} key={data} className="skeleton-stack">
                <div className="skeleton-wrapper">
                  {page === 'Logofolio' && (
                    <Skeleton variant="rectangular" width="100%" height={20} />
                  )}
                  <Skeleton variant="rectangular" width="100%" height={200} />
                  {page !== 'Logofolio' && (
                    <div>
                      <Skeleton variant="text" width="100%" height={50} />
                    </div>
                  )}
                </div>
                <div className="skeleton-wrapper">
                  <div>
                    <Skeleton variant="rectangular" width="100%" height={200} />
                  </div>
                  {page !== 'Logofolio' && (
                    <div>
                      <Skeleton variant="text" width="100%" height={50} />
                    </div>
                  )}
                </div>
              </Stack>
            )
          })
        }

      </div>
    </>
  );
};

export default LoaderSkeleton;
