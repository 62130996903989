import React, { useEffect, useState } from 'react'
import './style.css'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const PageLoader = () => {
  const [loading, setLoading] = useState(true)
  // const location = useLocation()

  // useEffect(()=> {
  //     setLoading(true)
  // }, [location?.pathname])


  // useEffect(()=> {
  //   setTimeout(() => {
  //     setLoading(false)
  //   }, 700);
  // }, [location?.pathname])

  return (
    <>
      <div class={`preloader ${loading ? 'loading' : ''}`}>
        <div class="circle delay-150"></div>
        <div class="circle delay-75"></div>
        <div class="circle delay-0"></div>
      </div>
    </>
  )
}

export default PageLoader