import React,{useState} from 'react';
//${active ? 'active' : ''}
const Tab = ({ id }) => {
  const [showProject, setshowProject] = useState(true);
  const [showScope, setshowScope] = useState(false);
  const [showIndustry, setshowIndustry] = useState(false);
  const [transformed, setTransformed] = useState(false);

  const showCategory = (category) => {
    if (category == 'projects') {
      localStorage.setItem("active", "projects");
      setshowProject(true);
      setshowScope(false);
      setshowIndustry(false);
      setTransformed(true);
    } else if (category == 'scope') {
      localStorage.setItem("active", "scope");

      setshowProject(false);
      setshowScope(true);
      setshowIndustry(false);
      setTransformed(true);
    } else if (category == 'industry') {
      localStorage.setItem("active", "industry");
      setshowProject(false);
      setshowScope(false);
      setshowIndustry(true);
    } else {
      localStorage.setItem("active", "projects");
      setshowProject(true);
      setshowScope(false);
      setshowIndustry(false);
    }

  }
  const activetab = localStorage.getItem("active");
  if (activetab !== null) {
    //showCategory(activetab);
  }

  return (
    <>
    <ul
      className="nav nav-tabs justify-content-md-end justify-content-start border-0 page-tabs"
      id="myTab"
      role="tablist" key={id}
      //ref={scrollContainerRef}
    >
      <li
        className="nav-item list-group-item project-nav-item"
        role="presentation"
        id="tab-1"
      >
        <button
          className="nav-link active bg-transparent border-0 px-3 text-start text-uppercase"
          id="home-tab"
          data-bs-toggle="tab"
          data-bs-target="#home-tab-pane"
          type="button"
          role="tab"
          aria-controls="home-tab-pane"
          aria-selected="true"
          onClick={() => showCategory("projects")}
        >
          All{" "}
          <span>
            Projects
          <div
              //className="divider-1 trans-00"
              id="divider-all"
              //style={{ transform: "translateX(0%)" }}
              className={`divider-1 ${(activetab=='projects' || activetab==null) ? 'transformed' : (activetab=='scope' || showScope)? 'myTabActivetransform' : (activetab=='industry' || showIndustry)? 'myTabActivetransform' : 'notransformed'}`}
            />

          </span>
        </button>
      </li>
      <li
        className="nav-item list-group-item scope-nav-item"
        role="presentation"
        id="tab-2"
      >
        <button
          className="nav-link bg-transparent border-0 px-0 text-start text-uppercase"
          id="profile-tab"
          data-bs-toggle="tab"
          data-bs-target="#profile-tab-pane"
          type="button"
          role="tab"
          aria-controls="profile-tab-pane"
          aria-selected="false"
          onClick={() => showCategory("scope")}
        >
          Sort by{" "}
          <span>
            Scope
         <div
              // className="divider-1 trans-00"
              id="divider-scope"
              // style={{ transform: "translateX(-100%)" }}
              className={`divider-1 ${(activetab=='scope' || showScope) ? 'transformed' : (activetab=='industry' || showIndustry) ? 'myTabActivetransform' : 'notransformed'}`}
            />

          </span>
        </button>
      </li>
      <li
        className="nav-item list-group-item industry-nav-item"
        role="presentation"
        id="tab-3"
        type="button"
      >
        <button
          className="nav-link bg-transparent border-0 text-start text-uppercase"
          id="contact-tab"
          data-bs-toggle="tab"
          data-bs-target="#contact-tab-pane"
          type="button"
          role="tab"
          aria-controls="contact-tab-pane"
          aria-selected="false"
          onClick={() => showCategory("industry")}
        >
          Sort by{" "}
          <span>
            industry
         <div
              //className="divider-1 trans-00"
              id="divider-industry"
              //style={{ transform: "translateX(-200%)" }}
              className={`divider-1 ${(activetab=='industry' || showIndustry) ? 'transformed' : 'notransformed'}`}
            />

          </span>
        </button>
      </li>
    </ul>
  </>
  );
};

export default Tab;