import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import $ from "jquery";
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";

function NavBar() {
  //const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  let { pathname } = useLocation();
  const homeClass = pathname === "/" ? "home" : "";
  const projectClass = pathname.match(/^\/project/) ? "active" : "";
  const logomarksClass = pathname.match(/^\/logofolio/) ? "active" : "";
  const clientClass = pathname.match(/^\/clients/) ? "active" : "";
  const aboutClass = pathname.match(/^\/about/) ? "active" : "";
  const contactClass = pathname.match(/^\/contact/) ? "active" : "";

  const show_12 = () => {
    $('.hidden-up').addClass("show");
    $('body').addClass("overflow-hidden");
  };

  const hide_12 = () => {
    $('.hidden-up').removeClass("show");
    $('body').removeClass("overflow-hidden");
  };

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  const toggle = () => setIsOpen(!isOpen);
  const hide = () => setIsOpen(false);

  return (
    <>
      {/* Header-start */}
      {/* <div className={homeClass + projectClass} > */}
      {/* <!-- Header-start --> */}
      <div className="header bg-none">
        {/*Mobile Menu*/}
        <nav className="navbar fixed-top-1 d-sm-block d-md-block d-lg-none" style={{background: 'white'}} data-bs-theme="black">
          <div className="container px-1">
          <Link className="navbar-brand" to="/" 
          // onClick={(event) => { event.preventDefault(); window.location.href='/' }}
          >
              <img src="./img/pmd-logo-black.svg"
            width="auto" height="48px" className="img-fluid" />
            </Link>
            <button className="bg-transparent border-0 px-0" type="button" onClick={show_12}> <img src="./img/menu-icon.svg"
              className="collaps-close" width="30px" height="auto" /></button>
            <div className="hidden-up">
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasExampleLabel"><Link to="/" onClick={(event) => { event.preventDefault(); window.location.href='/' }}><img src="./img/pmd-logo-black.svg"
                  width="57px" height="auto" /></Link></h5>
                <button className="btn-close" onClick={hide_12}>
                  <img src="./img/collaps-close.svg" className="collaps-open" width="23px" height="auto" />
                </button>
              </div>
              <div className="offcanvas-body">
                <div className="menu-content">
                  <div className="row">
                    <div className="col-12">
                      <div className="nav-icons mb-2">
                        <ul className="list-unstyled d-flex mb-0">
                          <li>
                          <a href="https://www.linkedin.com/company/prasun-mazumdar-design/?trk=mini-profile" target="_blank">
                              <img
                                src="./img/social-icons/linkedin.svg"
                                className="img-fluid"
                              />
                          </a>
                          </li>
                          <li>
                          <a href="https://www.instagram.com/pmdindia/" target="_blank">
                              <img
                                src="./img/social-icons/instagram.svg"
                                className="img-fluid"
                              />
                            </a>
                          </li>
                          <li>
                          <a href="https://www.facebook.com/pmdindia" target="_blank">
                              <img
                                src="./img/social-icons/facebook.svg"
                                className="img-fluid"
                              />
                            </a>
                          </li>
                          <li>
                          <a href="https://www.youtube.com/@pmdindia" target="_blank">
                              <img
                                src="./img/social-icons/youtube.svg"
                                className="img-fluid"
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <p className="mb-0">
                        <a target="_blank" href="https://maps.google.com/maps/dir//PMDIndia+G+3%2F10+G+Block,+DLF+Phase+1,+Sector+26+Gurugram,+Haryana+122002/@28.4732147,77.1055832,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x390ce2099137797f:0xb38315516012139b" className="direction text-white">
                          Get Directions <i className="far fa-arrow-up" />
                        </a>{" "}
                      </p>
                    </div>
                    <div
                      className="col-12 position-absolute mob-menu"
                      style={{ bottom: "10%" }}
                    >
                      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                          {" "}
                          <Link className="nav-link mob-link" to="/project" onClick={toggle}>
                            PROJECTS
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          {" "}
                          <Link className="nav-link mob-link" to="/logofolio" onClick={toggle}>
                            LOGOFOLIO
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          {" "}
                          <Link className="nav-link mob-link" to="/clients" onClick={toggle}>
                            CLIENTS
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          {" "}
                          <Link className="nav-link mob-link" to="/about" onClick={toggle}>
                            ABOUT
                          </Link>{" "}
                        </li>
                        <li className="nav-item">
                          {" "}
                          <Link className="nav-link mob-link" to="/contact" onClick={toggle}>
                            CONTACT
                          </Link>{" "}
                        </li>
                      </ul>
                      <div className="right mob d-flex align-items-center justify-content-center">
                        <ul className="list-unstyled list-group m-auto">
                          <li>
                            <img
                              src="./img/initative-icon.svg"
                              width="45px"
                              height="auto"
                              className="me-2"
                            />
                            <a href="https://thesparrow.in/" target="_blank" className="text-white">
                              OUR ART INITIATIVE
                            </a>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="backto-pro dis-none">
            <Link to="/project" className="back-to-project color-gray dis-none">
              <p className="ibm-sans text-md-end text-start fs-6 mb-0 mt-2 underline-off-2">
                <svg
                  width={16}
                  height={15}
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-2"
                >
                  <path d="M3 15V13H10.1C11.15 13 12.0625 12.6667 12.8375 12C13.6125 11.3333 14 10.5 14 9.5C14 8.5 13.6125 7.66667 12.8375 7C12.0625 6.33333 11.15 6 10.1 6H3.8L6.4 8.6L5 10L0 5L5 0L6.4 1.4L3.8 4H10.1C11.7167 4 13.1042 4.525 14.2625 5.575C15.4208 6.625 16 7.93333 16 9.5C16 11.0667 15.4208 12.375 14.2625 13.425C13.1042 14.475 11.7167 15 10.1 15H3Z" />
                </svg>
                BACK TO PROJECTS
              </p>
            </Link>
            <div className="product-fix-tabs">
              {/*Fixed Tabs*/}
              <div
                className="filter-tab text-md-end fixed-tabs position-fixed text-start bg-white start-0 d-none"
                id="tile-1"
              >
                <ul
                  className="nav nav-tabs justify-content-md-end justify-content-start border-0"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item list-group-item" role="presentation">
                    <button
                      className="nav-link active bg-transparent border-0 px-3  text-start text-uppercase"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    // onclick="all_projects()"
                    >
                      All{" "}
                      <span>
                        Projects
                        <div
                          className="divider-1"
                          id="divider-for-all"
                          style={{ transform: "translateX(0%)" }}
                        />
                      </span>
                    </button>
                  </li>
                  <li className="nav-item list-group-item" role="presentation">
                    <button
                      className="nav-link bg-transparent border-0 px-0 text-start text-uppercase"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab-pane"
                      aria-selected="false"
                    // onclick="scope()"
                    >
                      Sort by{" "}
                      <span>
                        Scope
                        <div
                          className="divider-1"
                          id="divider-for-scope"
                          style={{ transform: "translateX(-100%)" }}
                        />
                      </span>
                    </button>
                  </li>
                  <li className="nav-item list-group-item" role="presentation">
                    <button
                      className="nav-link bg-transparent border-0 text-start text-uppercase"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab-pane"
                      aria-selected="false"
                    // onclick="industry()"
                    >
                      Sort by{" "}
                      <span>
                        industry
                        <div
                          className="divider-1"
                          id="divider-for-industry"
                          style={{ transform: "translateX(-200%)" }}
                        />
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
              {/*Fixed Tabs End*/}
            </div>
          </div>
        </nav>
        {/*Desktop Menu*/}
        <nav className="navbar navbar-expand-lg px-4 d-none d-md-none d-lg-block" style={{background: 'white'}}>
          <div className="container px-0">
            {" "}
            <Link className="navbar-brand" to="/" 
            // onClick={(event) => { event.preventDefault(); window.location.href='/' }}
            >
              <img
                src="./img/pmd-logo-black.svg"
                alt=""
                width="auto"
                height="48px"
                img-fluid="true"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {" "}
              <span className="navbar-toggler-icon" />{" "}
            </button>
            <div className="collapse navbar-collapse test" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-5">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={"nav-link " + projectClass}
                    to="/project"
                  >
                    PROJECTS
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={"nav-link " + logomarksClass}
                    to="/logofolio"
                  >
                    LOGOFOLIO
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={"nav-link " + clientClass}
                    to="/clients"
                  >
                    CLIENTS
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={"nav-link " + aboutClass}
                    to="/about"
                  >
                    ABOUT
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={"nav-link " + contactClass}
                    to="/contact"
                  >
                    CONTACT
                  </Link>
                </li>
              </ul>
              <div className="right d-flex align-items-center">
                <ul className="list-unstyled list-group me-5 overflow-hidden">
                  <li>
                    <img src="./img/initative-icon.svg" width="45px" height="auto" />{" "}
                    <a href="https://thesparrow.in/" target="_blank" className="d-inline-block text-decoration-none">
                      OUR ART INITIATIVE
                    </a>
                  </li>
                </ul>
                <div className="nav-icons social-icons">
                  <ul className="list-unstyled d-flex mb-0">
                    <li>
                    <a href="https://www.linkedin.com/company/prasun-mazumdar-design/?trk=mini-profile" target="_blank">
                        <img
                          src="./img/social-icons/linkedin.svg"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                    <a href="https://www.instagram.com/pmdindia/" target="_blank">
                        <img
                          src="./img/social-icons/instagram.svg"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                    <a href="https://www.facebook.com/pmdindia" target="_blank">
                        <img
                          src="./img/social-icons/facebook.svg"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                    <a href="https://www.youtube.com/@pmdindia" target="_blank">
                        <img
                          src="./img/social-icons/youtube.svg"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="backto-pro dis-none">
            <Link
              to="/project"
              className="back-to-project color-gray"
            >
              <p className="ibm-sans text-md-end text-start fs-6 mb-0 mt-2 underline-off-2">
                <svg
                  width={16}
                  height={15}
                  viewBox="0 0 16 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="me-2"
                >
                  <path d="M3 15V13H10.1C11.15 13 12.0625 12.6667 12.8375 12C13.6125 11.3333 14 10.5 14 9.5C14 8.5 13.6125 7.66667 12.8375 7C12.0625 6.33333 11.15 6 10.1 6H3.8L6.4 8.6L5 10L0 5L5 0L6.4 1.4L3.8 4H10.1C11.7167 4 13.1042 4.525 14.2625 5.575C15.4208 6.625 16 7.93333 16 9.5C16 11.0667 15.4208 12.375 14.2625 13.425C13.1042 14.475 11.7167 15 10.1 15H3Z" />
                </svg>
                BACK TO PROJECTS
              </p>
            </Link>
            <div className="product-fix-tabs">
              {/*Fixed Tabs*/}
              <div
                className="filter-tab fixed-tabs dis-none text-md-end text-start position-fixed bg-white start-0"
                id="tile-1"
              >
                <ul
                  className="nav nav-tabs justify-content-md-end justify-content-start border-0"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item list-group-item" role="presentation">
                    <button
                      className="nav-link active bg-transparent border-0 px-3  text-start text-uppercase"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    // onclick="all_projects()"
                    >
                      All{" "}
                      <span>
                        Projects
                        <div
                          className="divider-1"
                          id="divider-in-all"
                          style={{ transform: "translateX(0%)" }}
                        />
                      </span>
                    </button>
                  </li>
                  <li className="nav-item list-group-item" role="presentation">
                    <button
                      className="nav-link bg-transparent border-0 px-0 text-start text-uppercase"
                      id="profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#profile-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="profile-tab-pane"
                      aria-selected="false"
                    // onclick="scope()"
                    >
                      Sort by{" "}
                      <span>
                        Scope
                        <div
                          className="divider-1"
                          id="divider-in-scope"
                          style={{ transform: "translateX(-100%)" }}
                        />
                      </span>
                    </button>
                  </li>
                  <li className="nav-item list-group-item" role="presentation">
                    <button
                      className="nav-link bg-transparent border-0 text-start text-uppercase"
                      id="contact-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#contact-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="contact-tab-pane"
                      aria-selected="false"
                    // onclick="industry()"
                    >
                      Sort by{" "}
                      <span>
                        industry
                        <div
                          className="divider-1"
                          id="divider-in-industry"
                          style={{ transform: "translateX(-200%)" }}
                        />
                      </span>
                    </button>
                  </li>
                </ul>
              </div>
              {/*Fixed Tabs End*/}
            </div>
          </div>  
        </nav>
        {/*Desktop Menu*/}
      </div>

      {/* <!-- Header-End --> */}

      {/* </div> */}
    </>
  );
}

export default NavBar;
