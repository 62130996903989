import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation, useParams } from 'react-router-dom';

// Import components
// Import CSS
import "./Assets/css/bootstrap.min.css";
import "./Assets/css/style.css";
import configData from "./config.json";
// Import Pages and Components
import Preloader from "./components/Preloader";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import ProjectDetails from "./components/Projects/ProjectDetails";
import Footer from "./components/Footer";
import Contact from "./components/Contact/Contact";
import Clients from "./components/Clients/Clients";
import NotFoundPage from './components/NotFoundPage';
import Logofolio from "./components/Logofolio/Logofolio";
import LogofolioDetails from "./components/Logofolio/LogofolioDetails";
import SkeletonLoader from "./components/LoaderSkeleton/LoaderSkeleton";
import PageLoader from "./components/pageLoader/PageLoader";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const body = document.body;
    body.classList.add('loading')
    setTimeout(() => {
      body.classList.remove('loading')
    }, 400);
  }, [pathname]);

  return null;
}

function App() {
  const [load, updateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);


  

  return (
    <Router basename={configData.BASE_URL}>
      <ScrollToTop />
      <PageLoader/>
      
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/project" exact component={Projects} />
        <Route path="/logofolio" exact component={Logofolio} />
        <Route path="/logofolio/logofolio-details/:id" component={LogofolioDetails} />
        <Route path="/clients" exact component={Clients} />
        <Route path="/about" exact component={About} />
        <Route path="/contact" exact component={Contact} />
        <Route path="/project/:productName" component={ProjectDetails} />
        <Route path="LoaderSkeleteon" component={SkeletonLoader} />
        {/* Catch-all route for 404 Not Found page */}
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  );
}

export default App;
