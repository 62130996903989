import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Navbar from "../Navbar";
import Footer from "../Footer";
import $ from 'jquery';
import { useEffect } from "react";

function Contact() {
  useEffect(() => {
    $('body').removeClass('overflow-hidden');
    $('.footer-info').removeClass('z-inc');
    var prevScrollPos = window.pageYOffset;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      // Scroll down 
      if (prevScrollPos < currentScrollPos) {
        $(".header").addClass("header-hidden").removeClass("header-visible");
        $("#tile-1").addClass("header-hidden-m").removeClass("header-visible-m");
        $('.footer-info').removeClass('z-inc');
      }
      // Scroll up 
      else {
        var scroll = $(window).scrollTop();
        if (scroll <= 100) {
          $(".header").removeClass("header-hidden").removeClass("header-visible");
          $("#tile-1").removeClass("header-hidden-m").removeClass("header-visible-m");
          $('.footer-info').removeClass('z-inc');
        }else{
          $(".header").removeClass("header-hidden").addClass("header-visible");
          $("#tile-1").removeClass("header-hidden-m").addClass("header-visible-m");
          $('.footer-info').removeClass('z-inc');
        }
      }
      prevScrollPos = currentScrollPos;
    };

    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    if (width <= 768) {

        $("main").click(function () {
            $('.overlay-123').removeClass('d-block');
        });

        $(window).scroll(function () {
            $(".footer-info").removeClass("z-inc");
            if ($(window).scrollTop() + $(window).height() > ($(document).height() - 280)) {
                //you are at bottom
                $(".footer-info").addClass("z-inc");
            }
        });
        $(".footer-info .animate-image span").click(function(){
          $('.footer-info').addClass('z-inc');
          $('#btn-back-to-top').addClass('d-none');
          $('.overlay-123').addClass('d-block');
          disableScroll();
      });

    }

    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    if (width >= 768) {

        $(window).scroll(function () {
            $(".footer-info").removeClass("z-inc");
            if ($(window).scrollTop() + $(window).height() > ($(document).height() - 660)) {
                //you are at bottom
                $(".footer-info").addClass("z-inc");
                console.log(width);
            }
        });

        $(".animate-image").hover(
          function () {
              $(".footer-info").addClass("z-inc");
              $('#btn-back-to-top').addClass('d-none');
              //$('body').addClass('overflow-hidden');
              $('.overlay-123').addClass('d-block');
              disableScroll();
          },
          function () {
              $('#btn-back-to-top').removeClass('d-none');
              //$('body').removeClass('overflow-hidden');
              $('.overlay-123').removeClass('d-block');
              enableScroll();
    
          }
      );

    }

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 200) {
        $(".header").removeClass("bg-none");
        $("#btn-back-to-top").addClass("d-block");
      } else {
        $(".header").addClass("bg-none");
        $("#btn-back-to-top").removeClass("d-block");
      }
    });
  
        
   
    $(".overlay-123").click(function(){
      $('.overlay-123').removeClass('d-block'); 
      setTimeout(function() { $('.footer-info').removeClass('z-inc'); }, 500);
      enableScroll();
   });
 // left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; } 
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt); 
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}
  })

  return (
    <div className="contact">
      <div className="header bg-none">
          <Navbar />
        </div>
      <main className="main-sec">
        <section className="contact-page">
          <div className="container px-0">
            <div className="intro">
              <h4 className="fw-normal rel-tab about-head f-m-0 color-black letter-space mb-0">
                Space predates all life, serving as the mother to all within.
              <br />
                <br />
          Our space, while not an ideal ecosystem, aims to nurture and
          eventually birth us. Expect productivity and great tea here.
        </h4>
            </div>
          </div>
          {/*       <img src="img/contact-image.jpg" class="img-fluid">*/}
          <div
            id="carouselExampleIndicators"
            className="carousel slide pointer-event"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={0}
                className="active"
                aria-current="true"
                aria-label="Slide 1"
              />
            </div>
            <div className="carousel-inner">
              <div className="carousel-item active">
                {" "}
                <video loop autoPlay muted>
                  <source src="./video/PmdShowreel[S].mp4" type="video/mp4" />
                </video>
                {" "}
              </div>
            </div>
            {/*<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="visually-hidden">Previous</span> </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="visually-hidden">Next</span> </button>*/}
          </div>
          <div className="contact-info pv-60">
            <div className="container px-0">
              <div className="row g-5">
                <div className="col-md-4">
                  <div className="row align-items-end">
                    <div className="col-12 col-mob-6">
                      <img
                        src="img/pmd-logo-black.svg"
                        width="100%"
                        className="img-fluid mb-5"
                      />
                    </div>
                    <div className="col-6 ord-1 col-mob-12 m-b-up">
                      <div className="divider w-100 mt-md-0 mb-md-1 mt-1 mb-3 mobile-vis" />
                      <div className="mob-flex justify-content-between align-items-center">
                        <div className="social-links">
                          <ul className="list-unstyled d-flex justify-content-start mb-md-3 mb-0">
                            <li>
                              <a target="_blank" href="https://www.linkedin.com/company/prasun-mazumdar-design/?trk=mini-profile">
                                <img
                                  src="./img/social-icons/linkedin.svg"
                                  className="img-fluid"
                                  width={34}
                                />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.instagram.com/pmdindia/" target="_blank">
                                <img
                                  src="./img/social-icons/instagram.svg"
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.facebook.com/pmdindia" target="_blank">
                                <img
                                  src="./img/social-icons/facebook.svg"
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.youtube.com/@pmdindia" target="_blank">
                                <img
                                  src="./img/social-icons/youtube.svg"
                                  className="img-fluid"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                        <p className="mb-0 text-sm-start text-md-center text-start">
                          <a
                            target="_blank"
                            href="https://maps.google.com/maps/dir//PMDIndia+G+3%2F10+G+Block,+DLF+Phase+1,+Sector+26+Gurugram,+Haryana+122002/@28.4732147,77.1055832,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x390ce2099137797f:0xb38315516012139b"
                            className="direction color-black fs-5 ibm-sans text-decoration-none d-flex align-items-center"
                          >
                            <span>Get Directions</span>
                            <i className="far fa-arrow-up" />
                          </a>
                        </p>
                      </div>
                    </div>
                    <div className="col-6 ful-1">
                      <div className="text-start">
                        <p className="mb-md-3 mb-2">
                          <a
                            href="mailto:prasun@pmdindia.in"
                            className="email-tag color-black fs-5"
                          >
                            prasun@pmdindia.in
                    </a>{" "}
                        </p>
                        <p className="mb-0">
                          <a
                            href="tel:+919871350426"
                            className="color-black fs-5 text-decoration-none lh-base"
                          >
                            +91 9871350426
                    </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14029.291212447024!2d77.0970331!3d28.4698237!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb38315516012139b!2sPMDIndia!5e0!3m2!1sen!2sin!4v1607352952704!5m2!1sen!2sin"
                    width="100%"
                    height="100%"
                    frameBorder={0}
                    style={{ border: 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex={0}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Contact;
