import React, { useState, useEffect } from "react";
import { FetchService } from '../../service/FetchService';
import configData from "../../config.json";
import { Link } from "react-router-dom";
import $ from 'jquery';
import Navbar from "../Navbar";
import Footer from "../Footer";
import LoaderSkeleton from "../LoaderSkeleton/LoaderSkeleton";

function Clients() {
  const [data, setData] = useState(null);

  useEffect(() => {
    $('body').removeClass('overflow-hidden');
    $('.footer-info').removeClass('z-inc');
    var prevScrollPos = window.pageYOffset;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      // Scroll down 
      if (prevScrollPos < currentScrollPos) {
        $(".header").addClass("header-hidden").removeClass("header-visible");
        $("#tile-1").addClass("header-hidden-m").removeClass("header-visible-m");
        $('.footer-info').removeClass('z-inc');
      }
      // Scroll up 
      else {
        var scroll = $(window).scrollTop();
        if (scroll <= 100) {
          $(".header").removeClass("header-hidden").removeClass("header-visible");
          $("#tile-1").removeClass("header-hidden-m").removeClass("header-visible-m");
          $('.footer-info').removeClass('z-inc');
        } else {
          $(".header").removeClass("header-hidden").addClass("header-visible");
          $("#tile-1").removeClass("header-hidden-m").addClass("header-visible-m");
          $('.footer-info').removeClass('z-inc');
        }
      }
      prevScrollPos = currentScrollPos;
    };

    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    if (width <= 768) {

      $("main").click(function () {
        $('.overlay-123').removeClass('d-block');
      });

      $(window).scroll(function () {
        $(".footer-info").removeClass("z-inc");
        if ($(window).scrollTop() + $(window).height() > ($(document).height() - 280)) {
          //you are at bottom
          $(".footer-info").addClass("z-inc");
        }
      });

      $(".footer-info .animate-image span").click(function () {
        $('.footer-info').addClass('z-inc');
        $('#btn-back-to-top').addClass('d-none');
        $('.overlay-123').addClass('d-block');
        disableScroll();
      });

    }

    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    if (width >= 768) {

      $(window).scroll(function () {
        $(".footer-info").removeClass("z-inc");
        if ($(window).scrollTop() + $(window).height() > ($(document).height() - 660)) {
          //you are at bottom
          $(".footer-info").addClass("z-inc");
          console.log(width);
        }
      });

      $(".animate-image").hover(
        function () {
          $(".footer-info").addClass("z-inc");
          $('#btn-back-to-top').addClass('d-none');
          //$('body').addClass('overflow-hidden');
          $('.overlay-123').addClass('d-block');
          disableScroll();
        },
        function () {
          $('#btn-back-to-top').removeClass('d-none');
          //$('body').removeClass('overflow-hidden');
          $('.overlay-123').removeClass('d-block');
          enableScroll();
        }
      );
    }

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 200) {
        $(".header").removeClass("bg-none");
        $("#btn-back-to-top").addClass("d-block");
      } else {
        $(".header").addClass("bg-none");
        $("#btn-back-to-top").removeClass("d-block");
      }
    });




    $(".overlay-123").click(function () {
      $('.overlay-123').removeClass('d-block');
      setTimeout(function () { $('.footer-info').removeClass('z-inc'); }, 500);
      enableScroll();
    });

    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    function preventDefault(e) {
      e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; }
      }));
    } catch (e) { }

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    // call this to Disable
    function disableScroll() {
      window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
      window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
      window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
      window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    }

    // call this to Enable
    function enableScroll() {
      window.removeEventListener('DOMMouseScroll', preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener('touchmove', preventDefault, wheelOpt);
      window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    }

  })

  const showAllScopes = (item) => {
    const category = item.scope_category;
    let splitArray = category.split(",");

    // Add spaces after each comma
    let formattedString = splitArray.join(", ");

    // Replace the last comma with "and" and remove the space after it
    formattedString = formattedString.replace(/,([^,]*)$/, " and$1");
    return formattedString;
  };

  useEffect(() => {
    const fetchService = new FetchService();
    const config = {
      method: 'GET',
      url: configData.SERVER_URL + 'product/clientlist?items=200',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y'
      }
    }
    fetchService.getService(config).then(data => setData(data["groupedByIndustry"]));
  }, []);

  return (
    <div className="client">
      <div className="header bg-none">
        <Navbar />
      </div>
      <main className="main-sec">
        <section className="contact-page about-page">
          {/*<div className="container px-0">
            <div className="row mb-5  pb-4 rel-tab">
             <div className="col-12 col-md-3">
                <h1 className="display-5 ibm-sans lh-1 color-black fw-normal text-uppercase">
                  Client List
                </h1>
              </div> */}
          <div className="container px-0">
            <div className="intro">

              <h4 className="fw-normal about-head f-m-0 mt-0 color-black mb-0">
              Our client list reflects not just the number of brands we've worked with, but the diverse sectors we've explored. The brilliance in our work stems from our efforts and the insights gained from these varied industries, creating a cohesive result.
              </h4>
            </div>


            <div className="client-listings pv-60" >
              <div className="row"  >
                <div className="col-md-5 col-sm-12">
                  <h5 className="ibm-sans contact-page color-black tablet-title client-head mb-0">
                    INDUSTRY
                  </h5>
                </div>
                <div className="col-md-7 col-sm-12">
                  <div className="row">
                    <div className="col-md-4 d-md-block d-sm-none d-none">
                      <h5 className="ibm-sans color-black client-head">CLIENT LIST</h5>
                    </div>
                    <div className="col-md-8 d-md-block d-sm-none d-none pl-52">
                      <h5 className="ibm-sans color-black client-head">
                        PROJECT TITLE
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-lists">
                {data && Object.entries(data).map(([category, clientsData], index) => (<>
                  <div key={index} className="row project">
                    <div className="col-12 d-md-none d-block">
                      <h5 className="bm-serif fw-normal fs-3 border-bottom border-dark pb-2 mb-3 color-black">{category}</h5>
                    </div>
                    <div className="col-md-5 d-md-block d-sm-none d-none pt-4">
                      <h5 className="ibm-serif fw-normal fs-3 color-black">{category == "Fmcg" ? "FMCG" : category}</h5>
                    </div>
                    <div className="col-md-7">
                      {Object.entries(clientsData).map(([client, products]) => (
                        <div key={client} className="row py-4">
                          <div className="col-md-4 col-sm-4 col-5">
                            <div className="client-name pt-3">{client}</div>
                          </div>

                          <div className="col-md-8 col-sm-8 col-7 pl-52">
                            <div className="client-name">
                              <ul className="project-list pt-2">
                                {products.map((product) => (
                                  <Link className="link-fs-22"
                                    to={{
                                      pathname: `/project/${product.productName.toLowerCase().replace(/[^a-z0-9-]/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '')}`,
                                      state: { data: product._id },
                                    }}>
                                    <li key={product._id}>{product.show_clientname == true ? showAllScopes(product) : product.productName}</li>
                                  </Link>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                  </div>
                  {index !== Object.keys(data).length - 1 ? <div className="divider desktop-vis"></div> : null}
                </>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>

  );
}

export default Clients;
